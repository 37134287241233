import { ref, inject, provide } from 'vue';

interface TooltipMethods {
  open: (id: string, description: string, title: string, position?: string) => void;
  close: () => void;
}

const TooltipSymbol = Symbol();

export function provideTooltip(methods: TooltipMethods) {
  provide(TooltipSymbol, methods);
}

export function useTooltip() {
  const tooltip = inject<TooltipMethods>(TooltipSymbol);

  if (!tooltip) {
    throw new Error(
      'Tooltip not provided. Make sure to call provideTooltip in a parent component'
    );
  }

  return tooltip;
}
